// 最外层盒子样式
%out-box {
  // width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以30；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

.voluteer-container {
  @extend %out-box;
  background: url("https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg");

  .voluteer-crumbs {
    @extend %inside-box;
    padding-top: (90rem / 100);
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 20px;
    color: #761f1e;
    

    a {
      color: #273143;
      margin-right: 4px;
    }
  }

  .voluteer-info {
    @extend %inside-box;
    margin: 0 auto;
    padding-top: (28rem / 100);

    &-item {
      width: (380rem / 100);
      height: (278rem / 100);
      float: left;
      margin-right: (30rem /100);
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid #e8e8e8;

      &:hover {
        box-shadow: 0 (3rem / 100) (6rem / 100)
          rgba($color: #c5c5c5, $alpha: 0.16);
      }

      &:last-child {
        margin-right: 0;
      }

      img {
        height: (223rem / 100);
      }

      &-desc {
        width: 100%;
        position: relative;
        height: (55rem / 100);
        background-color: #fff;
        padding: (14rem / 100) (17rem / 100);

        &-title {
          float: left;
          font-size: (20rem / 100);
        }

        &-number {
          float: right;
          font-size: (20rem / 100);
        }
      }
    }
  }
}
// 志愿服务
.volunteer-ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-li {
    width: (380rem / 100);
    height: (330rem / 100);
    box-sizing: border-box;
    background: #f3ede0;
    margin-right: (30rem / 100);
    border: 4px solid #f3ede0;
    margin-bottom: (30rem / 100);

    &-banner {
      width: (340rem / 100);
      height: (227rem / 100);
      margin: (20rem / 100) auto (7rem / 100);
      position: relative;

      &-total {
        min-width: (68rem / 100);
        height: (30rem / 100);
        background: #cd9e56;
        border-radius: 0 (20rem / 100) 0 0;
        position: absolute;
        left: 0;
        bottom: 0;
        // text-indent: (13rem / 100);
        line-height: (30rem / 100);
        font-size: (14rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
        text-align: center;
        text-indent: 0;
        width: auto;
      }
    }

    &-bottom {
      width: (340rem / 100);
      margin: 0 auto;

      &-title {
        width: 100%;
        height: (56rem / 100);
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: (11rem / 100);
      }
    }
  }

  &-li:nth-of-type(3n) {
    margin-right: 0;
  }

  &-li:hover {
    border: 4px solid #cd9e56;
  }
}
// 顶部
.culture-switch-type {
  display: flex;
  margin-bottom: (12rem / 100);
  margin-top: 0.2rem;

  &-title {
    font-size: (16rem / 100);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #761f1e;
    line-height: (32rem / 100);
    margin-right: (10rem / 100);
  }

  &-content {
    display: flex;
    flex-wrap: wrap;

    &-li {
      min-width: (100rem / 100);
      height: (32rem / 100);
      margin: 0 (30rem / 100) (12rem / 100) 0;
      background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li.png")
        no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: (32rem / 100);
      font-size: (16rem / 100);
      font-family: PingFangSC-Regular, PingFang SC;
      color: #273143;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      cursor: pointer;
    }
    .imgType {
      background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li.png")
      no-repeat;
      background-size: 100% 100%;
    }
    &-li-active {
      background: url("https://image.bookgo.com.cn/webculture/jm/activity_type_li.png")
        no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
      font-weight: 500;
    }
  }
}
.tab-secondNav-choose-item {
  font-size: 0.17rem;
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 0.14rem;
}
.tab-secondNav-choose-item {
  display: flex;
}
.tab-secondNav-choose-item li {
  margin-left: 10px;
}
.tab-secondNav-choose-item li {
  cursor: pointer;
}
.tab-secondNav-choose-item li:not(:first-child) {
  cursor: pointer;
  margin-left: 0.46rem;
}
.tabActivesecondNav {
  color: #761f1e;
  position: relative;
}

.tabActivesecondNavSpan {
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: -0.14rem;
  left: 0rem;
}
.tabActivesecondNavSubSpan {
  width: 80%;
  background: #991c10;
  height: 3px;
  display: inline-block;
  position: absolute;
  animation: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}
.tab-subSwitch {
  margin-top: 0.33rem;
}
.news-container {
  padding-top: 0.38rem;
}
